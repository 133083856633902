import React, { useState } from 'react';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { ContactReason, PortalAccessRequest } from '@/api/data-contracts';
import { submitAccessRequest } from '@/api/AccessRequest';
import { extractErrorMessage } from '@/utils/error';
import { toast } from "sonner";
import { styled } from '@/stitches.config';

const FormGrid = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '1rem',
  width: '100%',
  '@media (max-width: 640px)': {
    gridTemplateColumns: '1fr',
  }
});

const FormField = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
});

const RequiredIndicator = styled('span', {
  color: '#e11d48',
  marginLeft: '0.25rem',
});

const OptionalIndicator = styled('span', {
  color: '#9ca3af',
  marginLeft: '0.25rem',
  fontSize: '0.875rem',
});

const StyledLabel = styled(Label, {
  display: 'flex',
  alignItems: 'center',
  fontSize: '0.875rem',
  fontWeight: '500',
});

const StyledDialogContent = styled(DialogContent, {
  width: '95%',
  maxWidth: '600px',
  padding: '1.5rem',
  '@media (max-width: 640px)': {
    padding: '1rem',
  }
});

const StyledDialogFooter = styled(DialogFooter, {
  display: 'flex',
  marginTop: '1.5rem',
  gap: '0.5rem',
  '@media (max-width: 640px)': {
    flexDirection: 'column',
    '& button': {
      width: '100%',
    }
  }
});

interface RequestAccessDialogProps {
  organizationId: string;
  open: boolean;
  onOpenChange(open: boolean): void;
}

export const RequestAccessDialog = ({
  organizationId,
  open,
  onOpenChange,
}: RequestAccessDialogProps) => {
  const [contactName, setContactName] = useState<string>('');
  const [companyName, setCompanyName] = useState<string>('');
  const [emailAddress, setEmailAddress] = useState<string>('');
  const [reason, setReason] = useState<ContactReason>();
  const [position, setPosition] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleAccessRequest = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    const accessRequest: PortalAccessRequest = {
      contact_name: contactName,
      company_name: companyName,
      email_address: emailAddress,
      reason: reason,
      position: position,
      organization_id: organizationId
    };

    try {
      await submitAccessRequest(accessRequest);
      onOpenChange(false);
      toast.success("Access requested. We'll email you soon.");
    } catch (error) {
      toast.error("Couldn't request access: " + extractErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogHeader className="space-y-3">
        <DialogTitle className="text-xl">Request access to all documents</DialogTitle>
        <DialogDescription className="text-sm text-gray-600">
          Fill out this form to request access to all documents. We’ll review your request and get back to you shortly.
        </DialogDescription>
      </DialogHeader>

      <form onSubmit={handleAccessRequest} className="space-y-4 mt-4 px-6">
        <FormGrid>
          <FormField>
            <StyledLabel htmlFor="contactName">
              Contact name
              <RequiredIndicator>*</RequiredIndicator>
            </StyledLabel>
            <Input
              id="contactName"
              value={contactName}
              onChange={(e) => setContactName(e.target.value)}
              placeholder="Jane Doe"
              required
              className="h-10"
            />
          </FormField>

          <FormField>
            <StyledLabel htmlFor="companyName">
              Company name
              <RequiredIndicator>*</RequiredIndicator>
            </StyledLabel>
            <Input
              id="companyName"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              placeholder="Acme Inc."
              required
              className="h-10"
            />
          </FormField>
        </FormGrid>

        <FormField>
          <StyledLabel htmlFor="emailAddress">
            Email address
            <RequiredIndicator>*</RequiredIndicator>
          </StyledLabel>
          <Input
            id="emailAddress"
            type="email"
            value={emailAddress}
            onChange={(e) => setEmailAddress(e.target.value)}
            placeholder="security@acme.com"
            required
            className="h-10"
          />
        </FormField>

        <FormField>
          <StyledLabel htmlFor="position">
            Role
            <OptionalIndicator>(optional)</OptionalIndicator>
          </StyledLabel>
          <Input
            id="position"
            value={position}
            onChange={(e) => setPosition(e.target.value)}
            placeholder="Head of Security"
            className="h-10"
          />
        </FormField>

        <FormField>
          <StyledLabel htmlFor="reason">
            Reason
            <RequiredIndicator>*</RequiredIndicator>
          </StyledLabel>
          <Select onValueChange={(value) => setReason(value as ContactReason)} required>
            <SelectTrigger className="h-10">
              <SelectValue placeholder="Select a reason" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="EXISTING_CUSTOMER">I'm an existing customer</SelectItem>
              <SelectItem value="PROSPECTIVE_CUSTOMER">I'm interested in purchasing this product</SelectItem>
              <SelectItem value="OTHER">Other</SelectItem>
            </SelectContent>
          </Select>
        </FormField>

        <StyledDialogFooter>
          <Button
            type="button"
            variant="outline"
            onClick={() => onOpenChange(false)}
            className="h-10"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={isLoading}
            className="h-10"
          >
            {isLoading ? "Requesting..." : "Request access"}
          </Button>
        </StyledDialogFooter>
        </form>
    </Dialog>
  );
};
